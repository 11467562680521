<template>
    <el-dialog
        class="field_config"
        title="显示字段配置"
        width="640px"
        :visible.sync="isShow"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div class="opts">
            <el-button size="mini" type="primary" plain @click="onCheckAll">全选</el-button>
            <el-button size="mini" plain @click="onCancelAll">取消全选</el-button>
        </div>
        <el-checkbox-group v-model="activeField">
            <el-checkbox v-for="item in advertTableFieldList" :label="item.val" :key="item.val">
                {{ item.name }}
            </el-checkbox>
        </el-checkbox-group>
    </el-dialog>
</template>
<script>
export default {
    props: ['isShow', 'advertTableFieldList', 'currentField'],
    data() {
        return {
            activeField: [],
        }
    },
    methods: {
        onOpened() {
            this.activeField = [...this.currentField]
        },
        onClose() {
            this.$emit('onClose', this.activeField)
        },
        onCancelAll() {
            this.activeField = []
        },
        onCheckAll() {
            this.activeField = this.advertTableFieldList.map((item) => item.val)
        },
    },
}
</script>
<style lang="less" scoped>
.field_config {
    .el-checkbox-group {
        .el-checkbox {
            min-width: 120px;
        }
    }
    .el-dialog__body {
        padding: 16px 20px 20px;
    }
    .opts {
        display: flex;
        margin-bottom: 12px;
    }
}
</style>
