<template>
    <el-drawer
        :visible.sync="isShow"
        :before-close="onClose"
        :append-to-body="true"
        size="90vw"
        show-close
        :with-header="false"
        @opened="onOpened"
        class="chart_dialog"
    >
        <div class="chart_container" ref="chartImage">
            <div ref="chartContainer"></div>
        </div>
        <br />
        <div class="title">{{ title }}</div>
        <div class="steps">
            <div class="date_type">
                <div
                    class="item"
                    :class="dateType === item.value ? 'active_item' : ''"
                    v-for="(item, index) in dateTypeList"
                    :key="`dateType${index}`"
                    @click="onDateTypeChange(item.value)"
                >
                    <span class="label">{{ item.name }}</span>
                    <i class="el-icon-arrow-right" />
                </div>
            </div>
            <div class="dimension">
                <div v-if="dateType">
                    <div
                        class="item"
                        v-for="item in dimensionList"
                        :key="`dimensionList${item.value}`"
                        :class="checkDimension === item.value ? 'active_item' : ''"
                        @click="onCheckDimensionChange(item.value)"
                    >
                        <span class="label">{{ item.name }}</span>
                        <i class="el-icon-arrow-right" />
                    </div>
                </div>
            </div>
            <div class="category">
                <div v-if="checkDimension">
                    <div
                        class="item"
                        v-if="checkDimension === 'category'"
                        :class="checkCategorys.includes(-1) ? 'active_item' : ''"
                        @click="onCheckCategorysChange(-1)"
                    >
                        <span class="label">全选</span>
                        <i class="el-icon-arrow-right" />
                    </div>
                    <div
                        class="item"
                        v-for="item in scopeCategoryList"
                        :key="item.categoryNo"
                        :class="checkCategorys.includes(item.categoryNo) ? 'active_item' : ''"
                        @click="onCheckCategorysChange(item.categoryNo)"
                    >
                        <span class="label">{{ item.name }}</span>
                        <i class="el-icon-arrow-right" />
                    </div>
                </div>
            </div>
            <div class="provider">
                <div v-if="checkDimension === 'provider'">
                    <div
                        class="all_item"
                        @click="onCheckProvidersChange(-1)"
                        :class="checkProviders.includes(-1) ? 'active_all_item' : ''"
                    >
                        全选
                    </div>
                    <div class="row_group">
                        <template v-for="(item, index) in scopeProviderList">
                            <div
                                v-if="item.categoryNo === checkCategorys[0]"
                                class="row_item"
                                :key="`providerList${index}`"
                                :class="checkProviders.includes(item.providerNo) ? 'active_row_item' : ''"
                                @click="onCheckProvidersChange(item.providerNo)"
                            >
                                {{ item.name }}
                            </div>
                        </template>
                    </div>
                </div>
                <div v-else-if="checkDimension === 'category'" class="no_provider">
                    <div>无需选择渠道</div>
                </div>
            </div>
            <div class="field">
                <div
                    v-if="checkProviders.length !== 0 || (checkDimension === 'category' && checkCategorys.length !== 0)"
                >
                    <div class="row_group">
                        <template v-for="field in fieldList">
                            <div
                                class="row_item field_item"
                                :class="checkField.includes(field.val) ? 'active_row_item' : ''"
                                v-if="!field.unChartField"
                                :key="`field${field.val}`"
                                @click="onCheckFieldChange(field.val)"
                            >
                                {{ field.name }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="submit">
                <el-button type="primary" :disabled="checkField.length === 0" size="mini" @click="onCreactChart">
                    绘制
                </el-button>
            </div>
        </div>
        <div class="rule">
            <p>1. 指标最多同时选择10个</p>
            <p>2. 投放大类/渠道，无数据时不会显示在图表中</p>
        </div>
    </el-drawer>
</template>
<script>
import { getAdvertTel } from '@/js/api/reportApi.js'
import { advertFieldList, colors } from '@/js/config/reportForm.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { numToText } from '@/js/numTools.js'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import minMax from 'dayjs/plugin/minMax'

export default {
    props: ['isShow', 'currentParams', 'updateItemData', 'providerMap', 'providerList', 'categoryList', 'categoryMap'],
    data() {
        return {
            fieldList: advertFieldList,
            title: '',
            dateTypeList: [
                { name: '全部', value: 'all' },
                { name: '天维度', value: 'day' },
                { name: '周维度', value: 'week' },
                { name: '月维度', value: 'month' },
            ],
            dimensionList: [
                { name: '投放大类', value: 'category' },
                { name: '投放渠道', value: 'provider' },
            ],
            scopeCategoryList: [],
            scopeProviderList: [],

            dateType: null,
            checkDimension: '',
            checkCategorys: [],
            checkProviders: [],
            checkField: [],
        }
    },
    methods: {
        onOpened() {
            this.charts = null
            this.charts = window.echarts.init(this.$refs.chartContainer)
            this.initData()
            dayjs.extend(weekOfYear)
            dayjs.extend(minMax)
        },
        onClose() {
            this.dateType = null
            this.checkDimension = ''
            this.checkCategorys = []
            this.checkProviders = []
            this.checkField = []
            if (this.charts) this.charts.dispose()
            this.$emit('update:isShow', false)
        },
        onUpdateChart(data) {
            const legendData = []
            const xAxisData = []
            const seriesMap = {}
            const dataMap = {}
            data.forEach((item) => {
                if (!legendData.includes(item.field)) {
                    legendData.push(item.field)
                }
                if (!xAxisData.includes(item.name)) {
                    xAxisData.push(item.name)
                }
                if (!seriesMap[item.field]) {
                    seriesMap[item.field] = {
                        name: item.field,
                        type: item.percentage ? 'line' : 'bar',
                        data: [],
                        yAxisIndex: item.percentage ? 1 : 0,
                        label: { show: !item.percentage },
                    }
                }
                dataMap[`${item.name}-${item.field}`] = item.fieldVal
            })
            // 兼容 天/周/月查询是 数据缺失导致视图显示错位
            xAxisData.forEach((x) => {
                legendData.forEach((l) => {
                    const val = dataMap[`${x}-${l}`] || 0
                    seriesMap[l]['data'].push(val)
                })
            })
            this.charts.clear()
            this.charts.setOption({
                tooltip: {
                    trigger: 'axis',
                    className: 'echarts-tooltip',
                    formatter: (params) => {
                        let name = `<div class="title">${params[0]['name']}</div>`
                        params.forEach((item) => {
                            if (item.seriesType === 'line') {
                                name += `<div class="item">
                                        <span>${item.marker} ${item.seriesName}:</span>
                                        <span>${item.value}%</span>
                                    </div>`
                            } else {
                                name += `<div class="item">
                                        <span>${item.marker} ${item.seriesName}:</span>
                                        <span>${numToText(item.value)}</span>
                                    </div>`
                            }
                        })
                        return name
                    },
                },
                grid: { left: 10, right: 10, containLabel: true },
                legend: { data: legendData },
                xAxis: { type: 'category', data: xAxisData },
                yAxis: [
                    { type: 'value' },
                    {
                        type: 'value',
                        axisLabel: { formatter: '{value}%' },
                    },
                ],
                series: Object.values(seriesMap),
                color: colors,
                dataZoom: xAxisData.length > 50 ? [{ start: 0, end: 10 }] : [],
            })
        },

        // 第一步
        onDateTypeChange(val) {
            this.dateType = val
            this.checkDimension = ''
            this.checkCategorys = []
            this.checkProviders = []
            this.checkField = []
        },
        // 第二步
        onCheckDimensionChange(val) {
            this.checkDimension = val
            this.checkCategorys = []
            this.checkProviders = []
            this.checkField = []
        },
        // 第三步
        onCheckCategorysChange(val) {
            const list = this.scopeCategoryList
            if (val === -1 && this.checkCategorys.includes(val)) {
                this.checkCategorys = []
            } else if (val === -1 && !this.checkCategorys.includes(val)) {
                this.checkCategorys = list.map((i) => i.categoryNo)
                this.checkCategorys.push(-1)
            } else if (this.checkCategorys.includes(val)) {
                const index = this.checkCategorys.indexOf(val)
                this.checkCategorys.splice(index, 1)
                if (list.length >= this.checkCategorys.length && this.checkCategorys.includes(-1)) {
                    const index = this.checkCategorys.indexOf(-1)
                    this.checkCategorys.splice(index, 1)
                }
            } else {
                if (this.checkDimension === 'provider') {
                    this.checkCategorys = [val]
                } else {
                    this.checkCategorys.push(val)
                    if (list.length === this.checkCategorys.length && !this.checkCategorys.includes(-1)) {
                        this.checkCategorys.push(-1)
                    }
                }
            }
            this.checkProviders = []
            this.checkField = []
        },
        // 第四步
        onCheckProvidersChange(val) {
            const list = []
            this.scopeProviderList.forEach((p) => {
                if (this.checkCategorys[0] === p.categoryNo) {
                    list.push(p)
                }
            })
            if (val === -1 && this.checkProviders.includes(val)) {
                this.checkProviders = []
            } else if (val === -1 && !this.checkProviders.includes(val)) {
                this.checkProviders = list.map((i) => i.providerNo)
                this.checkProviders.push(-1)
            } else if (this.checkProviders.includes(val)) {
                const index = this.checkProviders.indexOf(val)
                this.checkProviders.splice(index, 1)
                if (list.length >= this.checkProviders.length && this.checkProviders.includes(-1)) {
                    const index = this.checkProviders.indexOf(-1)
                    this.checkProviders.splice(index, 1)
                }
            } else {
                this.checkProviders.push(val)
                if (list.length === this.checkProviders.length && !this.checkProviders.includes(-1)) {
                    this.checkProviders.push(-1)
                }
            }
            this.checkField = []
        },
        // 第五步
        onCheckFieldChange(val) {
            const list = this.fieldList
            if (this.checkField.includes(val)) {
                const index = this.checkField.indexOf(val)
                this.checkField.splice(index, 1)
                if (list.length >= this.checkField.length && this.checkField.includes(-1)) {
                    const index = this.checkField.indexOf(-1)
                    this.checkField.splice(index, 1)
                }
            } else {
                // 控制指标选中数量
                if (this.checkField.length >= 10) {
                    this.checkField.splice(0, 1)
                    this.checkField.push(val)
                    return
                }
                this.checkField.push(val)
                if (list.length === this.checkField.length && !this.checkField.includes(-1)) {
                    this.checkField.push(-1)
                }
            }
        },
        // 第六步 生成图表
        onCreactChart() {
            this.getOverviewAdvertisementApi()
        },
        async getOverviewAdvertisementApi() {
            // 大类
            let params = {
                ...this.currentParams,
                groupChannel: this.checkDimension,
                groupLevel: this.dateType,
            }
            if (this.checkDimension === 'provider') {
                params.categoryNo = this.checkCategorys[0]
            }
            params = filterQueryEmpty(params)
            const res = await getAdvertTel(params)
            const { dateType, checkDimension, checkCategorys, categoryMap, providerMap } = this
            const activeList = []
            res.data.forEach((item) => {
                if (checkDimension === 'category' && checkCategorys.includes(item.categoryNo)) {
                    activeList.push(item)
                } else if (checkDimension === 'provider' && this.checkProviders.includes(item.providerNo)) {
                    activeList.push(item)
                }
            })

            const completeList = []
            activeList.forEach((item) => {
                completeList.push(this.updateItemData({ ...item }))
            })
            const chartData = []

            if (dateType === 'all' && checkDimension === 'category') {
                completeList.forEach((item) => {
                    item.name = categoryMap[item.categoryNo]['name']
                    chartData.push(...this.getChartItem(item))
                })
            } else if (dateType === 'all' && checkDimension === 'provider') {
                completeList.forEach((item) => {
                    item.name = providerMap[item.providerNo]
                    chartData.push(...this.getChartItem(item))
                })
            } else if (checkDimension === 'category') {
                completeList.forEach((item) => {
                    item.name = item.time
                    if (dateType === 'week') {
                        item.name = this.getWeekTitle(item)
                    }
                    item.subtitle = categoryMap[item.categoryNo]['name']
                    chartData.push(...this.getChartItem(item))
                })
            } else if (checkDimension === 'provider') {
                completeList.forEach((item) => {
                    item.name = item.time
                    if (dateType === 'week') {
                        item.name = this.getWeekTitle(item)
                    }
                    item.subtitle = providerMap[item.providerNo]
                    chartData.push(...this.getChartItem(item))
                })
            }
            if (dateType !== 'all') {
                chartData.sort((a, b) => dayjs(a.name).valueOf() - dayjs(b.name).valueOf())
            }
            if (chartData.length === 0) {
                this.$message.warning('暂无数据')
            }
            this.onUpdateChart(chartData)
        },
        initData() {
            // 初始化 title
            const { trackTimes } = this.currentParams
            const title = [`名片时间: ${trackTimes[0]} ~ ${trackTimes[1]}`]
            this.title = title.join(' | ')
            // 初始化指标
            this.advertFieldListMap = {}
            advertFieldList.forEach((item) => {
                this.advertFieldListMap[item.val] = item
            })
            // 投放大类 和 渠道
            this.scopeProviderList = this.providerList
            this.scopeCategoryList = this.categoryList
        },
        getWeekTitle(item) {
            let weekNum = parseInt(item.time.substr(4))
            weekNum =
                dayjs()
                    .year(parseInt(item.time.substr(0, 4)))
                    .day() === 0
                    ? weekNum - 1
                    : weekNum
            let startDay = dayjs()
                .year(parseInt(item.time.substr(0, 4)))
                .week(weekNum)
                .startOf('week')
                .subtract(1, 'days')
            let endDay = dayjs()
                .year(parseInt(item.time.substr(0, 4)))
                .week(weekNum)
                .endOf('week')
                .subtract(1, 'days')
            startDay = dayjs.max(startDay, dayjs(this.currentParams.trackTimes[0]))
            endDay = dayjs.min(endDay, dayjs(this.currentParams.trackTimes[1]))
            return `${startDay.format('MM-DD')} ~ ${endDay.format('MM-DD')}`
        },
        getChartItem(data) {
            const list = []
            this.checkField.forEach((key) => {
                const item = { name: data.name, field: '', fieldVal: 0, percentage: false }
                const field = this.advertFieldListMap[key]['name']
                item.field = data.subtitle ? `${data.subtitle}: ${field}` : field
                if (this.advertFieldListMap[key]['isPrice']) {
                    item.fieldVal = this.getNum(data[key] / 100)
                } else {
                    item.fieldVal = this.getNum(data[key])
                }
                item.percentage = this.advertFieldListMap[key]['percentage']
                list.push(item)
            })
            return list
        },
        getNum(val) {
            if (val === '-' || !val) {
                return 0
            } else if (`${val}`.indexOf('%') > -1) {
                return Number(val.replace('%', ''))
            } else if (`${val}`.indexOf(',') > -1) {
                return Number(val.replace(/,/, ''))
            } else {
                return Number(val)
            }
        },
    },
}
</script>
<style lang="less" scoped>
.chart_dialog {
    .title {
        font-size: 14px;
        color: #333;
        margin-bottom: 12px;
    }
    .chart_container {
        height: 500px;
        overflow: hidden;
        background: #eee;
        > div {
            width: 100%;
            height: 100%;
        }
    }
    .steps {
        height: 320px;
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display: flex;
        > div {
            height: 100%;
            overflow-y: auto;
            border-right: 1px solid #eee;
        }
        .dimension,
        .date_type {
            flex-basis: 10%;
            min-width: 120px;
            flex-shrink: 0;
        }
        .category {
            flex-basis: 16%;
        }
        .provider {
            flex-basis: 36%;
        }
        .field {
            flex-basis: 18%;
        }
        .submit {
            flex-basis: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .item {
            display: flex;
            height: 50px;
            align-items: center;
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                background: #efefef;
            }
            .label {
                flex-grow: 1;
            }
            i {
                margin-left: 12px;
            }
        }
        .active_item {
            color: #409eff;
            font-weight: 700;
        }

        .all_item {
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                background: #efefef;
            }
        }

        .row_group {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 12px;
            .row_item {
                height: 32px;
                flex-basis: 33.33%;
                display: flex;
                font-size: 13px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover {
                    background: #efefef;
                }
            }
            .field_item {
                flex-basis: 50%;
            }
        }

        .active_all_item,
        .active_row_item {
            color: #409eff;
            font-weight: 700;
            background: rgba(64, 158, 255, 0.2) !important;
        }
        .no_provider {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 2em;
            align-items: center;
            img {
                width: 200px;
            }
        }
    }
    .rule {
        margin-top: 12px;
        font-size: 12px;
        line-height: 1.4em;
        color: #666;
        span {
            color: #f00;
        }
    }
}
</style>
