<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">名片时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="trackTimes"
                            type="daterange"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">出勤时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="attendTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">意向时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="intentTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">成单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="tradeTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">二阶成单</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="boTradeTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <i @click="isShowFieldConfig = true" class="setting el-icon-setting float_left_btn" />
                <i @click="isShowContrastDrawer = true" class="contrast el-icon-c-scale-to-original float_left_btn" />
                <i @click="isShowChartDrawer = true" class="chart el-icon-data-analysis float_left_btn" />

                <el-button @click="onReset">重置</el-button>
                <el-button @click="getAdvertReportData" type="primary">查询</el-button>
            </div>
        </div>
        <el-table
            class="report-table-2 advertReport"
            row-key="uuid"
            :border="true"
            :data="tableData"
            lazy
            :load="onTableLoad"
            height="calc(100vh - 300px)"
            @expand-change="onExpandChange"
            :expand-row-keys="expandList"
            :key="tableKey"
            @sort-change="onSortTable"
            ref="tableEl"
        >
            <el-table-column align="left" fixed="left" label="投放渠道" width="140" prop="title">
                <template slot-scope="scope">
                    <span class="title" @click="onShowAdvertProvider(scope.row)">{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="totalCostScale"
                label="投放成本占比"
                width="80"
                v-if="activeField.includes('totalCostScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="totalCostScale" /></template>
            </el-table-column>
            <el-table-column align="right" label="投放成本" width="90" v-if="activeField.includes('cost')">
                <template slot="header"><TableHeader source="Advert" propName="cost" /></template>
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.cost < 0 ? 'red' : '' }">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.cost / 100" :float="2" />
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="right" label="流水" width="100" v-if="activeField.includes('totalFee')">
                <template slot="header"><TableHeader source="Advert" propName="totalFee" /></template>
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.totalFee / 100" :float="0" />
                </template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="costScale"
                label="费比"
                width="80"
                v-if="activeField.includes('costScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="costScale" /></template>
                <template slot-scope="scope">
                    <span class="stress">{{ scope.row.costScale }}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="impressionText"
                label="展现"
                width="90"
                v-if="activeField.includes('impressionText')"
            >
                <template slot="header"><TableHeader source="Advert" propName="impression" /></template>
            </el-table-column>
            <el-table-column align="right" prop="ECPM" label="ECPM" width="80" v-if="activeField.includes('ECPM')">
                <template slot="header"><TableHeader source="Advert" propName="ECPM" /></template>
            </el-table-column>
            <el-table-column align="right" label="点击" width="90" v-if="activeField.includes('click')">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.click" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CPC" label="CPC" width="80" v-if="activeField.includes('CPC')">
                <template slot="header"><TableHeader source="Advert" propName="CPC" /></template>
            </el-table-column>
            <el-table-column align="right" prop="CTR" label="CTR" width="80" v-if="activeField.includes('CTR')">
                <template slot="header"><TableHeader source="Advert" propName="CTR" /></template>
            </el-table-column>
            <el-table-column align="right" label="表单" width="90" v-if="activeField.includes('formCnt')">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.formCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CVR" label="CVR" width="80" v-if="activeField.includes('CVR')">
                <template slot="header"><TableHeader source="Advert" propName="CVR" /></template>
            </el-table-column>
            <el-table-column align="right" prop="X" label="X值" width="80" v-if="activeField.includes('X')">
                <template slot="header"><TableHeader source="Advert" propName="X" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="totalOppoCntScale"
                label="名片量占比"
                width="90"
                v-if="activeField.includes('totalOppoCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="totalOppoCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                sortable="custom"
                label="名片"
                width="90"
                prop="oppoCnt"
                v-if="activeField.includes('oppoCnt')"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.oppoCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="numFormOppo"
                label="名片/表单"
                width="90"
                v-if="activeField.includes('numFormOppo')"
            />
            <el-table-column align="right" prop="CPA" label="CPA" width="80" v-if="activeField.includes('CPA')">
                <template slot="header"><TableHeader source="Advert" propName="CPA" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="friendCnt"
                label="加微"
                width="80"
                v-if="activeField.includes('friendCnt')"
            />
            <el-table-column
                align="right"
                prop="friendCntScale"
                label="加微率"
                width="80"
                v-if="activeField.includes('friendCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="friendCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="attendCnt"
                label="公开课出勤"
                width="90"
                v-if="activeField.includes('attendCnt')"
            >
                <template slot="header"><TableHeader source="Advert" propName="attendCnt" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="attendCntScale"
                label="出勤率"
                width="80"
                v-if="activeField.includes('attendCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="attendCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="attendDurationScale"
                label="平均出勤时长 (h)"
                width="80"
                v-if="activeField.includes('attendDurationScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="attendDurationScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="intentCnt"
                label="意向"
                width="80"
                v-if="activeField.includes('intentCnt')"
            >
                <template slot="header"><TableHeader source="Advert" propName="intentCnt" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="intentCntScale"
                label="意向率"
                width="80"
                v-if="activeField.includes('intentCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="intentCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="intentTradeCntScale"
                label="意向转化率"
                width="90"
                v-if="activeField.includes('intentTradeCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="intentTradeCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="totalTradeCnt"
                label="成交量"
                width="80"
                v-if="activeField.includes('totalTradeCnt')"
            >
                <template slot="header"><TableHeader source="Advert" propName="totalTradeCnt" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="totalTradeCntScale"
                label="销转"
                width="80"
                v-if="activeField.includes('totalTradeCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="totalTradeCntScale" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="attendTradeCnt"
                label="课后成交"
                width="80"
                v-if="activeField.includes('attendTradeCnt')"
            >
                <template slot="header"><TableHeader source="Advert" propName="attendTradeCnt" /></template>
            </el-table-column>
            <el-table-column
                align="right"
                prop="attendTotalCntScale"
                label="课后成交占比"
                width="80"
                v-if="activeField.includes('attendTotalCntScale')"
            >
                <template slot="header"><TableHeader source="Advert" propName="attendTotalCntScale" /></template>
            </el-table-column>
            <el-table-column align="right" prop="ARPU" label="ARPU" width="80" v-if="activeField.includes('ARPU')">
                <template slot="header"><TableHeader source="Advert" propName="ARPU" /></template>
            </el-table-column>
            <el-table-column align="right" prop="RPA" label="RPA" width="80" v-if="activeField.includes('RPA')">
                <template slot="header"><TableHeader source="Advert" propName="RPA" /></template>
            </el-table-column>
        </el-table>
        <AdvertProvider
            :isShow.sync="isShowAdvertProvider"
            :activeItem="activeItem"
            :currentParams="currentParams"
            :updateItemData="updateItemData"
            :activeField="activeField"
        />
        <ChartDrawer
            :isShow.sync="isShowChartDrawer"
            :currentParams="currentParams"
            :updateItemData="updateItemData"
            :providerMap="providerMap"
            :providerList="providerList"
            :categoryList="categoryList"
            :categoryMap="categoryMap"
        />
        <ContrastDrawer
            :isShow.sync="isShowContrastDrawer"
            :providerMap="providerMap"
            :categoryMap="categoryMap"
            :updateItemData="updateItemData"
            :projectNo="projectNo"
        />
        <FieldConfig
            :isShow.sync="isShowFieldConfig"
            :advertTableFieldList="advertTableFieldList"
            :currentField="activeField"
            @onClose="onCloseFieldConfig"
        />
        <Watermark />
    </div>
</template>
<script>
import { getProviderCategoryData } from '@/js/api/advertApi.js'
import { getAdvertTel } from '@/js/api/reportApi.js'
import { numFormat, getPercentage, getEllipsisNum } from '@/js/numTools.js'
import { advertTableFieldList } from '@/js/config/reportForm.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import ProjectSelect from '@/views/components/ProjectSelect'
import ChartDrawer from './components/advert/ChartDrawer.vue'
import ContrastDrawer from './components/advert/ContrastDrawer.vue'
import FieldConfig from './components/advert/FieldConfig.vue'
import AdvertProvider from './components/advert/AdvertProvider.vue'
import TableHeader from './components/TableHeader.vue'

export default {
    components: { ProjectSelect, AdvertProvider, ChartDrawer, TableHeader, ContrastDrawer, FieldConfig },
    data() {
        return {
            projectNo: '',
            providerList: [],
            providerMap: null,
            categoryList: [],
            categoryMap: null,
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本周',
                        onClick(picker) {
                            let start = dayjs().subtract(1, 'week')
                            if (dayjs().weekday() === 6) {
                                start = dayjs()
                            }
                            picker.$emit('pick', [start.endOf('week').toDate(), new Date()])
                        },
                    },
                    {
                        text: '上周',
                        onClick(picker) {
                            let end = dayjs().endOf('week').subtract(1, 'day').subtract(1, 'week')
                            if (dayjs().weekday() === 6) {
                                end = dayjs().subtract(1, 'day')
                            }
                            const endDate = end.toDate()
                            let startDate = end.subtract(6, 'day').toDate()
                            picker.$emit('pick', [startDate, endDate])
                        },
                    },
                    {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', [dayjs().startOf('month').toDate(), new Date()])
                        },
                    },
                    {
                        text: '上个月',
                        onClick(picker) {
                            let start = dayjs().subtract(1, 'month')
                            picker.$emit('pick', [start.startOf('month').toDate(), start.endOf('month').toDate()])
                        },
                    },
                    {
                        text: '近9周',
                        onClick(picker) {
                            let start = dayjs().endOf('weeks')
                            const n = dayjs().weekday() === 6 ? 8 : 9
                            picker.$emit('pick', [start.subtract(n, 'week').toDate(), new Date()])
                        },
                    },
                    {
                        text: '近7月',
                        onClick(picker) {
                            let start = dayjs().startOf('month')
                            picker.$emit('pick', [start.subtract(7, 'month').toDate(), new Date()])
                        },
                    },
                ],
            },
            trackTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            attendTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            intentTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            tradeTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            boTradeTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],

            tableKey: '',
            expandList: [],
            tableData: [],

            isShowAdvertProvider: false,
            activeItem: null,
            isShowChartDrawer: false,
            currentParams: null,

            isShowContrastDrawer: false,

            isShowFieldConfig: false,
            advertTableFieldList,
            activeField: [],
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    created() {
        const field = localStorage.getItem('advertReport-field')
        this.activeField = this.advertTableFieldList.map((item) => item.val)
        try {
            if (field) {
                this.activeField = JSON.parse(field) || []
            }
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
        onReset() {
            this.trackTimes = [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')]
            this.attendTimes = [
                dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs().endOf('day').format('YYYY-MM-DD'),
            ]
            this.intentTimes = [
                dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs().endOf('day').format('YYYY-MM-DD'),
            ]
            this.tradeTimes = [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')]
            this.boTradeTimes = [
                dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs().endOf('day').format('YYYY-MM-DD'),
            ]
            this.tableKey = uuidv4()
            this.expandList = []
            this.tableData = []
            this.getProviderCategoryDataApi()
        },

        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },
        onExpandChange(row, state) {
            this.expandRow = state ? row.title : ''
        },
        onShowAdvertProvider(item) {
            this.activeItem = {
                title: item.dialogTitle,
                categoryNo: item.categoryNo,
                providerNo: item.providerNo,
            }
            this.isShowAdvertProvider = true
        },
        onCloseFieldConfig(val) {
            this.activeField = val
            this.isShowFieldConfig = false
            this.tableKey = uuidv4()
            localStorage.setItem('advertReport-field', JSON.stringify(val))
        },
        onSortTable({ prop, order }) {
            this.tableData.forEach((item) => {
                if (item.children) {
                    item.children.sort((a, b) => {
                        return order === 'ascending'
                            ? parseFloat(a[prop]) - parseFloat(b[prop])
                            : parseFloat(b[prop]) - parseFloat(a[prop])
                    })
                }
            })
        },
        getAdvertReportData() {
            const { trackTimes, attendTimes, intentTimes, tradeTimes, boTradeTimes } = this
            let params = {
                groupLevel: 'all',
                projectNo: this.projectNo,
                groupChannel: 'category',
            }
            if (trackTimes && trackTimes.length) {
                params.trackTimes = trackTimes
            } else {
                return this.$message.warning('请选择名片时间')
            }
            if (attendTimes && attendTimes.length) {
                params.attendTimes = attendTimes
            }
            if (intentTimes && intentTimes.length) {
                params.intentTimes = intentTimes
            }
            if (tradeTimes && tradeTimes.length) {
                params.tradeTimes = tradeTimes
            }
            if (boTradeTimes && boTradeTimes.length) {
                params.boTradeTimes = boTradeTimes
            }
            params = filterQueryEmpty(params)
            this.currentParams = params
            getAdvertTel(params).then(async (res) => {
                const tableData = []
                let totalCost = 0
                let totalOppoCnt = 0
                res.data.forEach((item) => {
                    totalCost += item.cost
                    totalOppoCnt += item.oppoCnt
                })
                res.data.forEach((item) => {
                    if (item.categoryNo && this.categoryMap[item.categoryNo]) {
                        tableData.push(
                            this.updateItemData({
                                ...item,
                                title: this.categoryMap[item.categoryNo]['name'],
                                dialogTitle: this.categoryMap[item.categoryNo]['name'],
                                categorySeq: this.categoryMap[item.categoryNo]['seq'],
                                hasChildren: true,
                                totalCost,
                                totalOppoCnt,
                            })
                        )
                    }
                })
                tableData.sort((a, b) => a.categorySeq - b.categorySeq)
                // 设置默认展开
                if (this.expandRow) {
                    for (let i = 0; i < tableData.length; i++) {
                        const item = tableData[i]
                        if (item.title === this.expandRow) {
                            const data = await this.onGetLevel2Data(item)
                            item.hasChildren = false
                            item.children = data
                            this.expandList = [item.uuid]
                        }
                    }
                }
                this.tableData = tableData
                this.$refs.tableEl.clearSort()
            })
        },
        async onGetLevel2Data(tree) {
            const { trackTimes, attendTimes, intentTimes, tradeTimes } = this
            const params = {
                projectNo: this.projectNo,
                groupChannel: 'provider',
                groupLevel: 'all',
                categoryNo: tree.categoryNo,
                trackTimes,
            }
            if (attendTimes && attendTimes.length) params.attendTimes = attendTimes
            if (intentTimes && intentTimes.length) params.intentTimes = intentTimes
            if (tradeTimes && tradeTimes.length) params.tradeTimes = tradeTimes
            const res = await getAdvertTel(params)
            const tableData = []
            res.data.forEach((item) => {
                tableData.push(
                    this.updateItemData({
                        ...item,
                        categoryNo: tree.categoryNo,
                        title: this.providerMap[item.providerNo] || '-',
                        dialogTitle: `${tree.title}-${this.providerMap[item.providerNo] || '-'}`,
                        hasChildren: false,
                        totalCost: tree.totalCost,
                        totalOppoCnt: tree.totalOppoCnt,
                    })
                )
            })
            tableData.sort((a, b) => b.cost - a.cost)
            if (tableData.length === 0) {
                this.$message.warning('暂无数据')
            }
            return tableData
        },
        onTableLoad(tree, treeNode, resolve) {
            const { trackTimes, attendTimes, intentTimes, tradeTimes } = this
            const params = {
                groupChannel: 'provider',
                groupLevel: 'all',
                categoryNo: tree.categoryNo,
                trackTimes,
                projectNo: this.projectNo,
            }
            if (attendTimes && attendTimes.length) {
                params.attendTimes = attendTimes
            }
            if (intentTimes && intentTimes.length) {
                params.intentTimes = intentTimes
            }
            if (tradeTimes && tradeTimes.length) {
                params.tradeTimes = tradeTimes
            }
            getAdvertTel(params).then((res) => {
                const tableData = []
                res.data.forEach((item) => {
                    tableData.push(
                        this.updateItemData({
                            ...item,
                            categoryNo: tree.categoryNo,
                            title: this.providerMap[item.providerNo] || '-',
                            dialogTitle: `${tree.title}-${this.providerMap[item.providerNo] || '-'}`,
                            hasChildren: false,
                            totalCost: tree.totalCost,
                            totalOppoCnt: tree.totalOppoCnt,
                        })
                    )
                })
                tableData.sort((a, b) => b.cost - a.cost)
                if (tableData.length === 0) {
                    this.$message.warning('暂无数据')
                }
                this.tableData.forEach((item) => {
                    if (item.categoryNo === tree.categoryNo) {
                        item.children = tableData
                    }
                })
                resolve(tableData)
            })
        },
        updateItemData(item) {
            const data = { ...item, uuid: uuidv4() }
            const { totalFee, cost, impression, click, formCnt, friendCnt, totalCost, totalOppoCnt } = data
            const { oppoCnt, attendCnt, intentCnt, totalTradeCnt, attendTradeCnt, attendDuration } = data

            data.totalCostScale = this.getItemPercentage(cost, totalCost)
            data.costScale = this.getItemPercentage(cost, totalFee)
            data.impressionText = getEllipsisNum(data.impression)
            data.ECPM = '-'
            if (impression) {
                data.ECPM = getEllipsisNum((cost / 100 / impression) * 1000, 2)
            }
            data.CPC = '-'
            if (click) {
                data.CPC = numFormat(cost / 100 / click)
            }
            data.CTR = this.getItemPercentage(click, impression)
            data.CVR = this.getItemPercentage(formCnt, click)
            data.X = '-'
            if (impression) {
                data.X = getEllipsisNum((formCnt / impression) * 10000, 2)
            }

            data.totalOppoCntScale = this.getItemPercentage(oppoCnt, totalOppoCnt)
            data.numFormOppo = this.getItemPercentage(oppoCnt, formCnt)
            data.CPA = '-'
            data.attendDurationScale = '-'
            data.RPA = '-'
            if (oppoCnt) {
                data.CPA = numFormat(cost / 100 / oppoCnt, 2)
                data.attendDurationScale = numFormat(attendDuration / attendCnt / 3600, 2)
                data.RPA = numFormat(totalFee / 100 / oppoCnt, 2)
            }
            data.friendCntScale = this.getItemPercentage(friendCnt, oppoCnt)
            data.attendCntScale = this.getItemPercentage(attendCnt, oppoCnt)
            data.intentCntScale = this.getItemPercentage(intentCnt, oppoCnt)
            data.totalTradeCntScale = this.getItemPercentage(totalTradeCnt, oppoCnt)
            data.intentTradeCntScale = this.getItemPercentage(totalTradeCnt, intentCnt)
            data.ARPU = '-'
            if (totalTradeCnt) {
                data.ARPU = numFormat(totalFee / 100 / totalTradeCnt, 2)
            }
            data.attendTotalCntScale = this.getItemPercentage(attendTradeCnt, totalTradeCnt)
            return data
        },
        getItemPercentage(molecule, denominator) {
            let n = '-'
            if (denominator) {
                n = getPercentage(molecule / denominator) + '%'
            }
            return n
        },
        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerList = res.providerList
                this.providerMap = res.providerMap
                this.categoryList = res.categoryList
                this.categoryMap = res.categoryMap
                this.getAdvertReportData()
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-table {
    .el-table__placeholder {
        width: 16px;
    }
    .stress {
        color: #f00;
    }
    .el-table__indent {
        padding-left: 8px !important;
    }
    .title {
        cursor: pointer;
        border-bottom: 1px solid;
    }
}

.setting,
.contrast,
.chart {
    font-size: 24px;
    color: #409eff;
    cursor: pointer;
}
</style>
