<template>
    <span v-if="fieldMap[propName] && fieldMap[propName]['desc']">
        <el-tooltip placement="top">
            <div slot="content" class="prop_desc" v-html="fieldMap[propName]['desc']"></div>
            <span>{{ fieldMap[propName]['name'] }}</span>
        </el-tooltip>
    </span>
    <span v-else-if="fieldMap[propName]">{{ fieldMap[propName]['name'] }}</span>
    <span v-else>{{ propName }}</span>
</template>
<script>
import { telemarketFieldList, advertFieldList, publicLiveFieldList } from '@/js/config/reportForm.js'

export default {
    name: 'TableHeader',
    props: ['source', 'propName'],
    data() {
        return {
            fieldMap: null,
        }
    },
    created() {
        if (this.source === 'Telemarketing') {
            const map = {}
            telemarketFieldList.forEach((item) => {
                map[item.val] = item
            })
            this.fieldMap = map
        } else if (this.source === 'Advert') {
            const map = {}
            advertFieldList.forEach((item) => {
                map[item.val] = item
            })
            this.fieldMap = map
        } else if (this.source === 'PublicLive') {
            const map = {}
            publicLiveFieldList.forEach((item) => {
                map[item.val] = item
            })
            this.fieldMap = map
        }
    },
}
</script>
<style lang="less" scoped>
.prop_desc {
    max-width: 200px;
}
</style>
