<template>
    <el-dialog :visible.sync="isShow" width="90%" :before-close="onClose" @opened="onOpened">
        <div class="opts">
            <el-radio-group v-model="dateType" @change="getOverviewAdvertisementApi">
                <el-radio-button label="day">天</el-radio-button>
                <el-radio-button label="week">周</el-radio-button>
                <el-radio-button label="month">月</el-radio-button>
            </el-radio-group>
            <i @click="onExportImg" class="download el-icon-download" />
        </div>
        <div ref="dialogContent" :style="`min-width: ${tableWidth}px;position: relative;`">
            <div class="dialog_header">
                <div v-if="isShow" class="title">
                    <span>{{ title }}</span>
                    <span v-if="currentParams && currentParams.trackTimes">
                        [{{ currentParams.trackTimes[0] + ' ~ ' + currentParams.trackTimes[1] }}]
                    </span>
                </div>
            </div>
            <el-table
                class="report-table-1 advertProvider"
                :border="true"
                :data="tableData"
                lazy
                :height="tableHeight"
                :key="tableKey"
                :style="`min-width: ${tableWidth}px`"
            >
                <el-table-column align="center" prop="title" fixed="left" label="日期" width="110" />
                <el-table-column align="right" label="投放成本" width="90" v-if="activeField.includes('cost')">
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.cost / 100" :float="2" />
                    </template>
                </el-table-column>
                <el-table-column align="right" label="流水" width="100" v-if="activeField.includes('totalFee')">
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.totalFee / 100" :float="2" />
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    prop="costScale"
                    label="费比"
                    width="80"
                    v-if="activeField.includes('costScale')"
                >
                    <template slot-scope="scope">
                        <span class="stress">{{ scope.row.costScale }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    align="right"
                    prop="impressionText"
                    label="展现"
                    width="90"
                    v-if="activeField.includes('impressionText')"
                />
                <el-table-column
                    align="right"
                    prop="ECPM"
                    label="ECPM"
                    width="80"
                    v-if="activeField.includes('ECPM')"
                />
                <el-table-column align="right" label="点击" width="90" v-if="activeField.includes('click')">
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.click" :float="0" />
                    </template>
                </el-table-column>
                <el-table-column align="right" prop="CPC" label="CPC" width="80" v-if="activeField.includes('CPC')" />
                <el-table-column align="right" prop="CTR" label="CTR" width="80" v-if="activeField.includes('CTR')" />
                <el-table-column align="right" label="表单" width="90" v-if="activeField.includes('formCnt')">
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.formCnt" :float="0" />
                    </template>
                </el-table-column>
                <el-table-column align="right" prop="CVR" label="CVR" width="80" v-if="activeField.includes('CVR')" />
                <el-table-column align="right" prop="X" label="X值" width="80" v-if="activeField.includes('X')" />
                <el-table-column align="right" label="名片" width="90" v-if="activeField.includes('oppoCnt')">
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.oppoCnt" :float="0" />
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    prop="numFormOppo"
                    label="名片/表单"
                    width="90"
                    v-if="activeField.includes('numFormOppo')"
                />
                <el-table-column align="right" prop="CPA" label="CPA" width="80" v-if="activeField.includes('CPA')" />
                <el-table-column
                    align="right"
                    prop="friendCnt"
                    label="加微"
                    width="80"
                    v-if="activeField.includes('friendCnt')"
                />
                <el-table-column
                    align="right"
                    prop="friendCntScale"
                    label="加微率"
                    width="80"
                    v-if="activeField.includes('friendCntScale')"
                />

                <el-table-column
                    align="right"
                    prop="attendCnt"
                    label="公开课出勤"
                    width="80"
                    v-if="activeField.includes('attendCnt')"
                />
                <el-table-column
                    align="right"
                    prop="attendCntScale"
                    label="出勤率"
                    width="80"
                    v-if="activeField.includes('attendCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="attendDurationScale"
                    label="平均出勤时长 (h)"
                    width="80"
                    v-if="activeField.includes('attendDurationScale')"
                />
                <el-table-column
                    align="right"
                    prop="intentCnt"
                    label="意向"
                    width="80"
                    v-if="activeField.includes('intentCnt')"
                />
                <el-table-column
                    align="right"
                    prop="intentCntScale"
                    label="意向率"
                    width="80"
                    v-if="activeField.includes('intentCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="intentTradeCntScale"
                    label="意向转化率"
                    width="80"
                    v-if="activeField.includes('intentTradeCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="totalTradeCnt"
                    label="成交量"
                    width="80"
                    v-if="activeField.includes('totalTradeCnt')"
                />
                <el-table-column
                    align="right"
                    prop="totalTradeCntScale"
                    label="销转"
                    width="80"
                    v-if="activeField.includes('totalTradeCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="attendTradeCnt"
                    label="课后成交"
                    width="80"
                    v-if="activeField.includes('attendTradeCnt')"
                />
                <el-table-column
                    align="right"
                    prop="attendTotalCntScale"
                    label="课后成交占比"
                    width="80"
                    v-if="activeField.includes('attendTotalCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="ARPU"
                    label="ARPU"
                    width="80"
                    v-if="activeField.includes('ARPU')"
                />
                <el-table-column align="right" prop="RPA" label="RPA" width="80" v-if="activeField.includes('RPA')" />
                <el-table-column
                    align="right"
                    prop="currentFee"
                    label="当期流水"
                    width="90"
                    v-if="activeField.includes('currentFee')"
                >
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.currentFee / 100" :float="2" />
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    prop="currentTradeCntScale"
                    label="当期销转"
                    width="80"
                    v-if="activeField.includes('currentTradeCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="currentFeeRoi"
                    label="当期ROI"
                    width="80"
                    v-if="activeField.includes('currentFeeRoi')"
                />
                <el-table-column
                    align="right"
                    prop="periodFee"
                    label="跨期流水"
                    width="90"
                    v-if="activeField.includes('periodFee')"
                >
                    <template slot-scope="scope">
                        <NumFormat :key="scope.row.uuid" :num="scope.row.periodFee / 100" :float="2" />
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    prop="periodTradeCntScale"
                    label="跨期销转"
                    width="80"
                    v-if="activeField.includes('periodTradeCntScale')"
                />
                <el-table-column
                    align="right"
                    prop="periodFeeRoi"
                    label="跨期ROI"
                    width="80"
                    v-if="activeField.includes('periodFeeRoi')"
                />
            </el-table>
        </div>
    </el-dialog>
</template>
<script>
import { getAdvertTel } from '@/js/api/reportApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import minMax from 'dayjs/plugin/minMax'

export default {
    props: ['isShow', 'activeItem', 'currentParams', 'updateItemData', 'activeField'],

    data() {
        return {
            title: '',
            dateType: 'week',

            tableKey: '',
            tableData: [],
            tableWidth: 0,
            tableHeight: 'calc(100vh - 400px)',
        }
    },
    methods: {
        onClose() {
            this.dateType = 'week'
            this.$emit('update:isShow', false)
        },
        onOpened() {
            this.title = this.activeItem.title
            this.getOverviewAdvertisementApi()
            dayjs.extend(weekOfYear)
            dayjs.extend(minMax)
        },
        getOverviewAdvertisementApi() {
            let params = {
                ...this.currentParams,
                groupChannel: this.activeItem.providerNo ? 'provider' : 'category',
                groupLevel: this.dateType,
                categoryNo: this.activeItem.categoryNo,
                providerNo: this.activeItem.providerNo,
            }
            params = filterQueryEmpty(params)
            getAdvertTel(params).then((res) => {
                const tableData = []
                res.data.forEach((item) => {
                    if (this.dateType === 'week') {
                        let weekNum = parseInt(item.time.substr(4))
                        weekNum =
                            dayjs()
                                .year(parseInt(item.time.substr(0, 4)))
                                .day() === 0
                                ? weekNum - 1
                                : weekNum
                        let startDay = dayjs()
                            .year(parseInt(item.time.substr(0, 4)))
                            .week(weekNum)
                            .startOf('week')
                            .subtract(1, 'days')
                        let endDay = dayjs()
                            .year(parseInt(item.time.substr(0, 4)))
                            .week(weekNum)
                            .endOf('week')
                            .subtract(1, 'days')
                        startDay = dayjs.max(startDay, dayjs(this.currentParams.trackTimes[0]))
                        endDay = dayjs.min(endDay, dayjs(this.currentParams.trackTimes[1]))
                        item.title = `${startDay.format('MM-DD')} ~ ${endDay.format('MM-DD')}`
                    } else {
                        item.title = item.time
                    }
                })
                res.data.forEach((item) => {
                    tableData.push(this.updateItemData({ ...item }))
                })

                this.tableKey = uuidv4()
                this.tableData = tableData
            })
        },
        // 导出图片
        onExportImg() {
            const loading = this.$loading({ text: 'Loading', background: 'rgba(0, 0, 0, 0.8)' })
            const table = document.getElementsByClassName('advertProvider')[0]
            const tableBody = table.getElementsByClassName('el-table__body')[0]
            const tableHeader = table.getElementsByClassName('el-table__header')[0]
            this.tableWidth = tableBody.clientWidth
            this.tableHeight = tableBody.clientHeight + tableHeader.clientHeight
            const watermark = document.getElementById('watermark').cloneNode(true)
            watermark.id = uuidv4()
            watermark.style.position = 'absolute'
            this.$refs.dialogContent.appendChild(watermark)
            this.$nextTick(() => {
                window.html2canvas(this.$refs.dialogContent).then((canvas) => {
                    loading.close()
                    canvas.toBlob(
                        (blob) => {
                            this.tableWidth = 0
                            this.tableHeight = 'calc(100vh - 400px)'
                            this.$refs.dialogContent.removeChild(watermark)
                            const csvUrl = URL.createObjectURL(blob)
                            let link = document.createElement('a')
                            link.download = `投放报表-${this.title}.jpg`
                            link.href = csvUrl
                            link.click()
                        },
                        'image/png',
                        1
                    )
                })
            })
        },
    },
}
</script>
<style lang="less" scoped>
.dialog_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    margin-bottom: 10px;
    font-size: 14px;
    color: #000;
}
.opts {
    position: absolute;
    left: 20px;
    top: 18px;
    display: flex;
    align-items: center;
    z-index: 9;
    /deep/ .el-radio-group {
        margin-right: 20px;
    }
}

/deep/ .el-dialog__headerbtn {
    z-index: 9;
}
/deep/ .el-dialog__header {
    padding: 0;
}
/deep/ .el-dialog__body {
    padding: 12px 20px 20px;
    min-height: 420px;
    box-sizing: border-box;
    overflow: hidden;
}
.download {
    font-size: 24px;
    color: #409eff;
    cursor: pointer;
    margin-left: 12px;
}
</style>
