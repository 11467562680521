<template>
    <el-drawer
        :visible.sync="isShow"
        :before-close="onClose"
        :append-to-body="true"
        size="90vw"
        show-close
        :with-header="false"
        class="contrast_dialog"
        @opened="onOpened"
    >
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">名片时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            v-model="trackTimes"
                            @change="getAdvertReportData"
                            type="daterange"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">出勤时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            @change="getAdvertReportData"
                            v-model="attendTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">意向时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            @change="getAdvertReportData"
                            v-model="intentTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">成单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            :picker-options="pickerOptions"
                            @change="getAdvertReportData"
                            v-model="tradeTimes"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd"
                        />
                    </span>
                </div>
            </div>
        </div>
        <el-table
            class="report-table-2 advertReport"
            ref="advertTable"
            row-key="uuid"
            :border="true"
            :data="tableData"
            lazy
            :load="onTableLoad"
            height="320px"
            @selection-change="onSelectionChange"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column align="left" fixed="left" label="投放渠道" width="140" prop="title" />
            <el-table-column align="right" prop="totalCostScale" label="投放成本占比" width="80" />
            <el-table-column align="right" label="投放成本" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.cost / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" label="流水" width="100">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.totalFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="costScale" label="费比" width="80">
                <template slot-scope="scope">
                    <span class="stress">{{ scope.row.costScale }}</span>
                </template>
            </el-table-column>
            <el-table-column align="right" prop="impressionText" label="展现" width="90" />
            <el-table-column align="right" prop="ECPM" label="ECPM" width="80" />
            <el-table-column align="right" label="点击" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.click" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CPC" label="CPC" width="80" />
            <el-table-column align="right" prop="CTR" label="CTR" width="80" />
            <el-table-column align="right" label="表单" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.formCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CVR" label="CVR" width="80" />
            <el-table-column align="right" prop="X" label="X值" width="80" />
            <el-table-column align="right" prop="totalOppoCntScale" label="名片量占比" width="90" />
            <el-table-column align="right" label="名片" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.oppoCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="numFormOppo" label="名片/表单" width="90" />
            <el-table-column align="right" prop="CPA" label="CPA" width="80" />
            <el-table-column align="right" prop="friendCnt" label="加微" width="80" />
            <el-table-column align="right" prop="friendCntScale" label="加微率" width="80" />
            <el-table-column align="right" prop="subscribeCnt" label="公众号关注" width="80" />
            <el-table-column align="right" prop="subscribeCntScale" label="公众号关注率" width="80" />
            <el-table-column align="right" prop="unsubscribeCnt" label="公众号取关" width="80" />
            <el-table-column align="right" prop="unsubscribeCntScale" label="公众号取关率" width="80" />
            <el-table-column align="right" prop="attendCnt" label="公开课出勤" width="80" />
            <el-table-column align="right" prop="attendCntScale" label="出勤率" width="80" />
            <el-table-column align="right" prop="attendDurationScale" label="平均出勤时长 (h)" width="80" />
            <el-table-column align="right" prop="intentCnt" label="意向" width="80" />
            <el-table-column align="right" prop="intentCntScale" label="意向率" width="80" />
            <el-table-column align="right" prop="intentTradeCntScale" label="意向转化率" width="80" />
            <el-table-column align="right" prop="totalTradeCnt" label="成交量" width="80" />
            <el-table-column align="right" prop="totalTradeCntScale" label="销转" width="80" />
            <el-table-column align="right" prop="attendTradeCnt" label="课后成交" width="80" />
            <el-table-column align="right" prop="attendTotalCntScale" label="课后成交占比" width="80" />
            <el-table-column align="right" prop="ARPU" label="ARPU" width="80" />
            <el-table-column align="right" prop="RPA" label="RPA" width="80" />
            <el-table-column align="right" prop="currentFee" label="当期流水" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.currentFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="currentTradeCntScale" label="当期销转" width="80" />
            <el-table-column align="right" prop="currentFeeRoi" label="当期ROI" width="80" />
            <el-table-column align="right" prop="periodFee" label="跨期流水" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.periodFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="periodTradeCntScale" label="跨期销转" width="80" />
            <el-table-column align="right" prop="periodFeeRoi" label="跨期ROI" width="80" />
        </el-table>
        <el-table :border="true" :data="contrastTable" height="320px" :key="contrastTableKey">
            <el-table-column align="center" fixed="left" label="操作" width="100">
                <template slot-scope="scope">
                    <el-link @click="onDelRowData(scope.row)">删除</el-link>
                </template>
            </el-table-column>
            <el-table-column align="center" fixed="left" label="名片时间" width="120">
                <template slot-scope="scope">
                    <el-tooltip effect="light" placement="right">
                        <div slot="content">
                            <div>
                                出勤时间 {{ scope.row.attendTimes[0] || '--' }} ~ {{ scope.row.attendTimes[1] || '--' }}
                            </div>
                            <div>
                                意向时间 {{ scope.row.intentTimes[0] || '--' }} ~ {{ scope.row.intentTimes[1] || '--' }}
                            </div>
                            <div>
                                成单时间 {{ scope.row.tradeTimes[0] || '--' }} ~ {{ scope.row.tradeTimes[1] || '--' }}
                            </div>
                        </div>
                        <div>
                            <div>起 {{ scope.row.trackTimes[0] }}</div>
                            <div>止 {{ scope.row.trackTimes[1] }}</div>
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column align="center" fixed="left" label="投放渠道" width="100" prop="title" />
            <el-table-column align="right" prop="totalCostScale" label="投放成本占比" width="80" />
            <el-table-column align="right" label="投放成本" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.cost / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" label="流水" width="100">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.totalFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="costScale" label="费比" width="80">
                <template slot-scope="scope">
                    <span class="stress">{{ scope.row.costScale }}</span>
                </template>
            </el-table-column>
            <el-table-column align="right" prop="impressionText" label="展现" width="90" />
            <el-table-column align="right" prop="ECPM" label="ECPM" width="80" />
            <el-table-column align="right" label="点击" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.click" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CPC" label="CPC" width="80" />
            <el-table-column align="right" prop="CTR" label="CTR" width="80" />
            <el-table-column align="right" label="表单" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.formCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="CVR" label="CVR" width="80" />
            <el-table-column align="right" prop="X" label="X值" width="80" />
            <el-table-column align="right" prop="totalOppoCntScale" label="名片量占比" width="90" />
            <el-table-column align="right" label="名片" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.oppoCnt" :float="0" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="numFormOppo" label="名片/表单" width="90" />
            <el-table-column align="right" prop="CPA" label="CPA" width="80" />
            <el-table-column align="right" prop="friendCnt" label="加微" width="80" />
            <el-table-column align="right" prop="friendCntScale" label="加微率" width="80" />
            <el-table-column align="right" prop="subscribeCnt" label="公众号关注" width="80" />
            <el-table-column align="right" prop="subscribeCntScale" label="公众号关注率" width="80" />
            <el-table-column align="right" prop="unsubscribeCnt" label="公众号取关" width="80" />
            <el-table-column align="right" prop="unsubscribeCntScale" label="公众号取关率" width="80" />
            <el-table-column align="right" prop="attendCnt" label="公开课出勤" width="80" />
            <el-table-column align="right" prop="attendCntScale" label="出勤率" width="80" />
            <el-table-column align="right" prop="attendDurationScale" label="平均出勤时长 (h)" width="80" />
            <el-table-column align="right" prop="intentCnt" label="意向" width="80" />
            <el-table-column align="right" prop="intentCntScale" label="意向率" width="80" />
            <el-table-column align="right" prop="intentTradeCntScale" label="意向转化率" width="80" />
            <el-table-column align="right" prop="totalTradeCnt" label="成交量" width="80" />
            <el-table-column align="right" prop="totalTradeCntScale" label="销转" width="80" />
            <el-table-column align="right" prop="attendTradeCnt" label="课后成交" width="80" />
            <el-table-column align="right" prop="attendTotalCntScale" label="课后成交占比" width="80" />
            <el-table-column align="right" prop="ARPU" label="ARPU" width="80" />
            <el-table-column align="right" prop="RPA" label="RPA" width="80" />
            <el-table-column align="right" prop="currentFee" label="当期流水" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.currentFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="currentTradeCntScale" label="当期销转" width="80" />
            <el-table-column align="right" prop="currentFeeRoi" label="当期ROI" width="80" />
            <el-table-column align="right" prop="periodFee" label="跨期流水" width="90">
                <template slot-scope="scope">
                    <NumFormat :key="scope.row.uuid" :num="scope.row.periodFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="right" prop="periodTradeCntScale" label="跨期销转" width="80" />
            <el-table-column align="right" prop="periodFeeRoi" label="跨期ROI" width="80" />
        </el-table>
    </el-drawer>
</template>
<script>
import { getAdvertTel } from '@/js/api/reportApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

export default {
    props: ['isShow', 'projectNo', 'updateItemData', 'providerMap', 'categoryMap'],
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本周',
                        onClick(picker) {
                            let start = dayjs().subtract(1, 'week')
                            if (dayjs().day() === 6) {
                                start = dayjs()
                            }
                            picker.$emit('pick', [start.endOf('week').toDate(), new Date()])
                        },
                    },
                    {
                        text: '上周',
                        onClick(picker) {
                            let end = dayjs().endOf('week').subtract(1, 'day').subtract(1, 'week')
                            if (dayjs().day() === 6) {
                                end = dayjs().subtract(1, 'day')
                            }
                            const endDate = end.toDate()
                            let startDate = end.subtract(6, 'day').toDate()
                            picker.$emit('pick', [startDate, endDate])
                        },
                    },
                    {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', [dayjs().startOf('month').toDate(), new Date()])
                        },
                    },
                    {
                        text: '上个月',
                        onClick(picker) {
                            let start = dayjs().subtract(1, 'month')
                            picker.$emit('pick', [start.startOf('month').toDate(), start.endOf('month').toDate()])
                        },
                    },
                    {
                        text: '近9周',
                        onClick(picker) {
                            let start = dayjs().endOf('weeks')
                            const n = dayjs().day() === 6 ? 8 : 9
                            picker.$emit('pick', [start.subtract(n, 'week').toDate(), new Date()])
                        },
                    },
                    {
                        text: '近7月',
                        onClick(picker) {
                            let start = dayjs().startOf('month')
                            picker.$emit('pick', [start.subtract(7, 'month').toDate(), new Date()])
                        },
                    },
                ],
            },
            trackTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            attendTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            intentTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            tradeTimes: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],
            tableData: [],

            contrastTableKey: '',
            contrastTable: [],
        }
    },
    methods: {
        onOpened() {
            this.getAdvertReportData()
        },
        onClose() {
            this.contrastTable = []
            this.trackTimes = [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')]
            this.attendTimes = [
                dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs().endOf('day').format('YYYY-MM-DD'),
            ]
            this.intentTimes = [
                dayjs().startOf('month').format('YYYY-MM-DD'),
                dayjs().endOf('day').format('YYYY-MM-DD'),
            ]
            this.tradeTimes = [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')]
            this.tableData = []
            this.$emit('update:isShow', false)
        },
        onSelectionChange(list) {
            const uuids = this.contrastTable.map((item) => item.uuid)
            list.forEach((item) => {
                if (!uuids.includes(item.uuid)) {
                    this.contrastTable.push({
                        ...item,
                        trackTimes: this.trackTimes || [],
                        attendTimes: this.attendTimes || [],
                        intentTimes: this.intentTimes || [],
                        tradeTimes: this.tradeTimes || [],
                    })
                }
            })
            this.contrastTableKey = uuidv4()
        },
        onDelRowData(item) {
            let index = 0
            this.contrastTable.forEach((r, i) => {
                if (r.uuid === item.uuid) {
                    index = i
                }
            })
            this.contrastTable.splice(index, 1)
            this.contrastTableKey = uuidv4()
        },
        onShowAdvertProvider(item) {
            this.activeItem = {
                title: item.dialogTitle,
                categoryNo: item.categoryNo,
                providerNo: item.providerNo,
            }
            this.isShowAdvertProvider = true
        },
        async getAdvertReportData() {
            const { trackTimes, attendTimes, intentTimes, tradeTimes } = this
            let params = {
                groupLevel: 'all',
                projectNo: this.projectNo,
                groupChannel: 'category',
            }
            if (trackTimes && trackTimes.length) {
                params.trackTimes = trackTimes
            } else {
                return this.$message.warning('请选择名片时间')
            }
            if (attendTimes && attendTimes.length) {
                params.attendTimes = attendTimes
            }
            if (intentTimes && intentTimes.length) {
                params.intentTimes = intentTimes
            }
            if (tradeTimes && tradeTimes.length) {
                params.tradeTimes = tradeTimes
            }
            params = filterQueryEmpty(params)
            this.currentParams = params
            getAdvertTel(params).then((res) => {
                const tableData = []
                let totalCost = 0
                let totalOppoCnt = 0
                res.data.forEach((item) => {
                    totalCost += item.cost
                    totalOppoCnt += item.oppoCnt
                })
                res.data.forEach((item) => {
                    if (item.categoryNo && this.categoryMap[item.categoryNo]) {
                        tableData.push(
                            this.updateItemData({
                                ...item,
                                title: this.categoryMap[item.categoryNo]['name'],
                                dialogTitle: this.categoryMap[item.categoryNo]['name'],
                                categorySeq: this.categoryMap[item.categoryNo]['seq'],
                                hasChildren: true,
                                totalCost,
                                totalOppoCnt,
                            })
                        )
                    }
                })
                tableData.sort((a, b) => a.categorySeq - b.categorySeq)
                this.tableData = tableData
            })
        },
        onTableLoad(tree, treeNode, resolve) {
            const { trackTimes, attendTimes, intentTimes, tradeTimes } = this
            const params = {
                groupChannel: 'provider',
                groupLevel: 'all',
                categoryNo: tree.categoryNo,
                trackTimes,
            }
            if (attendTimes && attendTimes.length) {
                params.attendTimes = attendTimes
            }
            if (intentTimes && intentTimes.length) {
                params.intentTimes = intentTimes
            }
            if (tradeTimes && tradeTimes.length) {
                params.tradeTimes = tradeTimes
            }
            this.currentApi(params).then((res) => {
                const tableData = []
                res.data.forEach((item) => {
                    tableData.push(
                        this.updateItemData({
                            ...item,
                            categoryNo: tree.categoryNo,
                            title: this.providerMap[item.providerNo],
                            dialogTitle: `${tree.title}-${this.providerMap[item.providerNo]}`,
                            hasChildren: false,
                            totalCost: tree.totalCost,
                            totalOppoCnt: tree.totalOppoCnt,
                        })
                    )
                })
                tableData.sort((a, b) => b.cost - a.cost)
                if (tableData.length === 0) {
                    this.$message.warning('暂无数据')
                }
                resolve(tableData)
            })
        },
    },
}
</script>
<style lang="less" scoped>
.contrast_dialog {
    .field_container {
        margin: 30px 0 20px;
        .title {
            font-size: 14px;
            font-weight: 700;
            color: #444;
            margin-bottom: 12px;
        }
        .el-checkbox-group {
            .el-checkbox {
                min-width: 120px;
            }
        }
    }
}
</style>
